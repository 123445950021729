const BuildingBlocks = {};
BuildingBlocks.init = function($) {

	$(document).on('ready',function() {
		initSlideshowBlock();
		initOverviewSlideshow();
		initTestimonialSlideshow();
		featherlightGalleryThese();
		animateElements();
	});

	$(window).on('resize',function() {
	});

	$(window).on('load',function() {
		$(window).trigger('scroll');
	});


	$(window).on('scroll',function() {
		animateElements();
	});

	/* General animatable elements */
	function animateElements(){
		var a = $('.animation-element:not(.person):not(.start-animation)');
		if(!a.length){
			return;
		}
		$.each(a,function(){
			var et = $(this).offset().top;
			var st = $(window).scrollTop();
			var sb = $(window).scrollTop() + $(window).innerHeight();
			/*
			   The slide-top and slide-bottom elements have a transform applied to them so the offset().top returns the wrong value
			   We need to either add or subtract the height of the element to correct
			*/
			if($(this).hasClass('slide-top')){
				et -= $(this).outerHeight();
			}
			if($(this).hasClass('slide-bottom')){
				et += $(this).outerHeight();
			}
			if ((sb > et) || (st < et)){
				$(this).addClass('start-animation');
			}
		});
	}

	function featherlightGalleryThese(){
		if($('.bb-gallery').length){
			$('.bb-gallery').each(function() {
				$(this).find('a').featherlightGallery();
			});
		}
	}

	function initSlideshowBlock(){
		var $el = $('.bb-slideshow');
		if(!$el.length){
			return;
		}
		$.each($el,function(){
			var s = $(this).find('.slideshow');
			s.slick({
				arrows:				true,
				prevArrow:			'<a href="#" class="slick-prev arrow lev-icon-left-arrow"><span class="screen-reader-text">Previous Slide</span></a>',
				nextArrow:			'<a href="#" class="slick-next arrow lev-icon-right-arrow"><span class="screen-reader-text">Next Slide</span></a>',
				autoplay:			false,
				dots:				false,
				fade:				false,
				centerMode: 		true,
				variableWidth: 		true,
				variableHeight: 	true,
	  			infinite: 			true,
	  			slidesToShow: 		1,
			});
		});
	}

	function initOverviewSlideshow(){
		var $el = $('.bb-overview-block .ovs-testimonials');
		if(!$el.length){
			return;
		}
		$.each($el,function(){
			var s = $(this).find('.slideshow');
			s.slick({
				fade:				true,
				prevArrow:			'<span class="slick-prev arrow"></span>',
				nextArrow:			'<span class="slick-next arrow"></span>',
				speed:				500,
				adaptiveHeight: 	true,
				appendArrows:       $(this).find('.arrows'),
			});
		});
	}

	function initTestimonialSlideshow(){
		var $el = $('.bb-testimonials');
		if(!$el.length){
			return;
		}
		$.each($el,function(){
			var s = $(this).find('.slideshow');
			s.slick({
				fade:				true,
				prevArrow:			'<span class="slick-prev arrow"></span>',
				nextArrow:			'<span class="slick-next arrow"></span>',
				speed:				500,
				adaptiveHeight: 	true,
				appendArrows:       $(this).find('.arrows'),
			});
		});
	}

	$('.bb-overview-block .slideshow').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		var text = (nextSlide+1)+' of '+$(this).data('slides');
		$(this).parents('.bb-overview-block').find('.oftext').html(text);
	});

	$('.bb-testimonials .slideshow').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		var text = (nextSlide+1)+' of '+$(this).data('slides');
		$(this).parents('.bb-testimonials').find('.oftext').html(text);
	});

	$(document).on('click','.overview-menu a',function(e){
		e.preventDefault();
		var href = $(this).attr('href');
		var $el = $(href);
		if($el.length){
			$('html, body').animate({
				scrollTop: $el.offset().top
			}, 2000);
		}
	});
};

module.exports = BuildingBlocks;
