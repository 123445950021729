const MobileMenu = {};
const Hamburger = {};
const Breakpoint = 992;
const Expanders = {};


MobileMenu.init = function () {

	let hamburger = document.querySelector('.hamburger');
	let body = document.querySelector('body');
	let expanders = document.querySelectorAll('.menu-site-navigation-container .expander');


	hamburger.addEventListener('click',function (e) {
		Hamburger.toggle(hamburger,body);
	});

	expanders.forEach((expander) => {
		expander.addEventListener('click', function(e) {
			Expanders.toggle(expander);
		});
	});

};

Hamburger.toggle = function (burger, body) {
	body.classList.toggle('fullscreen-menu');
};

Expanders.toggle = function(expander) {
	expander.nextElementSibling.classList.toggle('open');
	expander.parentElement.classList.toggle('expanded');
	// 				jQuery(this).parent().toggleClass('expanded');
};


// MobileMenu.init = function($){
// 	$(document).ready(function() {
// 		$('.hamburger').click(function(e){
// 			e.preventDefault();
// 			$('body').toggleClass('fullscreen-menu');
// 		});
//
// 		// Detect if dropdown menu will go off screen
// 		$('.sub-menu').parent().hover(function() {
// 			var menu = $(this).find('ul');
// 			var menupos = $(menu).offset();
//
// 			if (menupos.left + menu.width() > $(window).width()) {
// 				$(menu).addClass('reverse');
// 			}
// 		});
//
// 		// Add parent menu item's link to the top of its submenu
// 		$('.sub-menu').each(function(i, el) {
// 			var $parentLink			= $(el).closest('li');
// 			var parentURL			= $parentLink.children('a').attr('href');
// 			var	parentLinkClasses	= $parentLink.attr('class').replace('current-menu-ancestor', '').replace('menu-item-has-children', '');
// 			var parentLinkContent	= $parentLink.html();
//
// 			jQuery(this).prepend('<li class="duplicate-link ' + parentLinkClasses + '"><a href="' + parentURL + '">' + $parentLink.children('a').html() + '</a></li>');
// 		});
//
// 		// Drill down
// 		$('#header .menu-item-has-children > a').on('click', function(event) {
// 			if( $('body').hasClass('fullscreen-menu') ) {
// 				event.preventDefault();
//
// 				$(this).siblings('.sub-menu').toggleClass('open');
// 				jQuery(this).parent().toggleClass('expanded');
// 			}
// 		});
// 	});
// };
module.exports = MobileMenu;
