const General = {};
General.init = function($) {
	var apply_forms = {};
	var auth_form = {};
	var restricted_urls = {};

	$(document).on('ready',function(){
		initLozad();
		//getRestrictedUrls();
		headerSticky();
		wrapIframes();
	});

	function initLozad(){
  	var observer = lozad();
  	observer.observe();
  }

  function scrollToTop(){
  	window.scrollTo({
  		top: 0,
  		left: 0,
  		behavior: 'smooth'
  	});
  }

	$(window).on('resize',function(){
	});

	$(window).on('scroll',function(){
		headerSticky();
	});

	$(document).on('click','.to-top',function(e){
		e.preventDefault();
		scrollToTop();
	});

	$(document).on('click','a[href^="#get-updates"]',function(e){
		e.preventDefault();
		preloadApplication($(this).attr('href'));
	});

	$(document).on('click','a[href^="#apply"]',function(e){
		e.preventDefault();
		preloadApplication($(this).attr('href'));
	});

	$(document).on('click','.lightbox-button a',function(e){
		e.preventDefault();
		var $el = $(this).parent().find('.lightbox-content');
		$el.removeClass('hide');
	});

	$(document).on('click','.lightbox-content .bg, .lightbox-content .close',function(e){
		e.preventDefault();
		$('.lightbox-content').addClass('hide');
	});

	$.featherlight.defaults.afterOpen = function(){
		$('.featherlight').focus();
		initLozad();
	};

	function headerSticky(){
		var t = $(window).scrollTop();
		if(t>100){
			$('body').addClass('sticky-nav');
		} else {
			$('body').removeClass('sticky-nav');
		}
	}

	function preloadApplication(id){
		if(apply_forms[id]){
			triggerApplication(id);
		} else {
			var data = {
				'action': 'get_apply_form',
				'form_id': id,
				'uri': window.location.pathname
			};
			$.post(ajaxURL, data, function(response) {
				var r = JSON.parse(response);
				if(r.status=='success'){
					apply_forms[r.form_id] = r.html;
					triggerApplication(r.form_id);
				}
			});
		}
	}

	function preloadAuth(url){
		if(auth_form[0]){
			triggerAuth();
		} else {
			var data = {
				'action': 'get_auth_form',
				'forward': url,
				'uri': window.location.pathname
			};
			$.post(ajaxURL, data, function(response) {
				var r = JSON.parse(response);
				if(r.status=='success'){
					auth_form[0] = r.html;
					triggerAuth();
				}
			});
		}
	}

	var config = {
		afterOpen: function(){$('body').addClass('featherlight-open');},
		afterClose: function(){$('body').removeClass('featherlight-open');},
	};

	function triggerApplication(id){
		$.featherlight(apply_forms[id],config);
	}

	function triggerAuth(){
		$.featherlight(auth_form[0],config);
	}

	function wrapIframes(){
		$('iframe').wrap('<div class="iframe-wrapper" />');
		$('.iframe-wrapper').wrap('<div class="iframe-container" />');
	}


};

module.exports = General;
