const MenuAccess = {};
MenuAccess.init = function( $ ) {

	$(document).ready(function() {
		var focusedClass = 'is-focused';

		var closeOpenMenus = function() {
			var focused = $( '.' + focusedClass );
			focused.removeClass( focusedClass );

			focused.children('[aria-expanded]').attr('aria-expanded', 'false');
		};
		var toggleSubmenu = function( event ) {
			var target = $( event.target );

			closeOpenMenus();

			var parents = target.parents('.menu-item');
			if ( parents.length ) {
				parents.addClass( focusedClass );
				parents.children('[aria-expanded]').attr('aria-expanded', 'true');
			}
		};

		document.addEventListener('focus', toggleSubmenu, true);
		document.addEventListener('click', closeOpenMenus, true);
	});

};
module.exports = MenuAccess;
