(function() {
  // IE 11 Polyfill
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}
  let Website = {};


  //Bring in our modules
  let BuildingBlocks = require('./theme/building-blocks.js');
  let General = require('./theme/general.js');
  let MobileMenu = require('./theme/mobile-menu.js');
  let MenuAccess = require('./theme/menu-accessibility.js');

  //Invocations
  window.addEventListener("DOMContentLoaded",function() {
  Website.BuildingBlocks = BuildingBlocks.init(jQuery);
  Website.General = General.init(jQuery);
  Website.MobileMenu = MobileMenu.init(jQuery);
  Website.MenuAccess = MenuAccess.init(jQuery);



  });

})();
